import { Box, Button, Group, Select, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form";
import { useSearchParams } from "react-router-dom";
import { MARQUES } from "types/Car";

/**
 * EDITION NORMALE
 * 
 * Immatriculation
 * Marques ( Mercedes )
 * Modèles ( Class C )
 * Année
 * 
 * EDITION AVANCEE
 * 
 * Kms ( 70 000 )
 * VO/VD/VN
 * Pays ( FR )
 * Version 350 AVANTGARDE 7GTRO
 * Couleur ( Noir )
 */

const SearchFilters = () => {

  const [, setSearchParams] = useSearchParams();

  const form = useForm({
    initialValues: {
      immatriculation: '',
      marque: '',
      modele: '',
      annee: '',
      kms: '',
      type: '',
      pays: '',
      version: '',
      couleur: '',
    },
  });

  const onSubmit = () => {
    let query = "";
    for (const key in form.values) {
      if ((form.values as any)[key].length > 0)
        query += `${key}=${(form.values as any)[key]}&`
    }
    setSearchParams(query)
    // https://mantine.dev/form/errors/
    // form.setErrors({ immatriculation: 'Too short', email: 'Invalid email' });
  }
  return (
    <>
      <Box>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            label="Immatriculation"
            placeholder="AB-768-KD"
            {...form.getInputProps('immatriculation')}
          />
          <Select label="Marque" placeholder="RENAULT" data={MARQUES} searchable
            {...form.getInputProps('marque')}
          />
          {/* <TextInput
            label="Marque"
            placeholder="Mercedes"
            {...form.getInputProps('marque')}
          /> */}
          {/* <AdvancedEdition > */}

          {/* <TextInput
              label="modele"
              placeholder="modele"
              {...form.getInputProps('modele')}
            />
            <TextInput
              label="Année"
              placeholder="Année"
              {...form.getInputProps('annee')}
            />
            <TextInput
              label="Kilomètres"
              placeholder="Kilomètres"
              {...form.getInputProps('kms')}
            />
            <TextInput
              label="type"
              placeholder="VO/VD/VN"
              {...form.getInputProps('type')}
            />
            <TextInput
              label="Pays"
              placeholder="France"
              {...form.getInputProps('pays')}
            />
            <TextInput
              label="Version"
              placeholder="Version 350 AVANTGARDE 7GTRO"
              {...form.getInputProps('version')}
            />
            <TextInput
              label="couleur"
              placeholder="Noir"
              {...form.getInputProps('couleur')}
            /> */}
          {/* </AdvancedEdition> */}

          <Group position="right" mt="md">
            <Button type="submit" fullWidth disabled={!form.isDirty()}>Rechercher</Button>
          </Group>
        </form>
      </Box>
    </>)
}

export default SearchFilters