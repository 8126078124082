interface ICar {
    _id: string;
    marque?: string;
    immatriculation: string;
    famille?: string;
    version?: string;
    createdBy: { username: string }
    primary_photos: IPhoto[]
    secondary_photos?: IPhoto[]
    km?: string
    provider: string | IProvider
    price?: number
}

export interface IPhoto {
    name: string;
    path: string;
    code: string
}

export interface IProvider { _id: string, user: string, codes: string[] }

export default ICar

export const MARQUES = [
    { value: 'audi', label: 'audi' },
    { value: 'bavaria', label: 'bavaria' },
    { value: 'bentley', label: 'bentley' },
    { value: 'bmw', label: 'bmw' },
    { value: 'chevrolet', label: 'chevrolet' },
    { value: 'citroen', label: 'citroen' },
    { value: 'daihatsu', label: 'daihatsu' },
    { value: 'dodge', label: 'dodge' },
    { value: 'ferrari', label: 'ferrari' },
    { value: 'fiat', label: 'fiat' },
    { value: 'ford', label: 'ford' },
    { value: 'harley-davidson', label: 'harley-davidson' },
    { value: 'honda', label: 'honda' },
    { value: 'hyundai', label: 'hyundai' },
    { value: 'jaguar', label: 'jaguar' },
    { value: 'jeep', label: 'jeep' },
    { value: 'kia', label: 'kia' },
    { value: 'land-rover', label: 'land-rover' },
    { value: 'ligier', label: 'ligier' },
    { value: 'maserati', label: 'maserati' },
    { value: 'mazda', label: 'mazda' },
    { value: 'mercedes', label: 'mercedes' },
    { value: 'mg', label: 'mg' },
    { value: 'mini', label: 'mini' },
    { value: 'mercedes-benz', label: 'mercedes-benz' },
    { value: 'nissan', label: 'nissan' },
    { value: 'opel', label: 'opel' },
    { value: 'peugeot', label: 'peugeot' },
    { value: 'porsche', label: 'porsche' },
    { value: 'quicksilver', label: 'quicksilver' },
    { value: 'renault', label: 'renault' },
    { value: 'seat', label: 'seat' },
    { value: 'skoda', label: 'skoda' },
    { value: 'smart', label: 'smart' },
    { value: 'suzuki', label: 'suzuki' },
    { value: 'toyota', label: 'toyota' },
    { value: 'volkswagen', label: 'volkswagen' },
    { value: 'volvo', label: 'volvo' },
    { value: 'yahama', label: 'yahama' },
    { value: 'yamaha', label: 'yamaha' }
]