import { useMutation, useQueryClient } from "@tanstack/react-query"
import usePhoto from "hooks/usePhoto"
import useS3 from "hooks/useS3"
import INativeFile from "types/NativeFile"

const useUploadPhotos = ({ onSuccess }: { onSuccess?: () => void }) => {
    const { addPrimaryPhoto, addSecondaryPhoto } = usePhoto()
    const { uploadToS3 } = useS3()
    const queryClient = useQueryClient()

    return useMutation(async ({ type, carId, code, pictures }: { type: 'primary' | 'secondary', carId: string, code: string, pictures: INativeFile[] }) => {
        for (let i = 0; i < pictures.length; i++) {
            const { key } = await uploadToS3(pictures[i].base64)
            if (type === 'primary')
                await addPrimaryPhoto(carId, [{ name: pictures[i].filename, code, path: key }])
            else
                await addSecondaryPhoto(carId, [{ name: pictures[i].filename, code, path: key }])
        }
    }, {
        onSuccess: async data => {
            console.log(data)
            queryClient.invalidateQueries(['car_by_id'])
            onSuccess?.()
        },
        onError: ((err) => {
            console.log(err);
        })
    })
}

export default useUploadPhotos