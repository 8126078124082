import { useQuery } from "@tanstack/react-query";
import http from '@plugin/http'
import { useSearchParams } from "react-router-dom";
import ICar, { IPhoto, IProvider } from "types/Car";
import useProviders from "hooks/useProviders";
import { ActionIcon, Button, Checkbox, CopyButton, Group, Image, Input, LoadingOverlay, Modal, Select, TextInput, Textarea, Tooltip, rem } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import useIsEnv from "hooks/useIsEnv";
import { IconCheck, IconCopy, IconSend } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import AddPhotoModal from "@components/AddPhotoModal";
import { useDisclosure } from "@mantine/hooks";

type Photo = IPhoto & { checked: boolean }

const DetailCar = () => {
    const [searchParams] = useSearchParams();

    const carProviders = useProviders()
    const [isEmailOpening, setIsEmailOpening] = useState(false)
    const [isEmailSending, setIsEmailSending] = useState(false)
    const [selectedCode, setSelectedCode] = useState<string>('none');
    const [filteredPrimaryPhotos, setFilteredPrimaryPhotos] = useState<Photo[]>([])
    const [filteredSecondaryPhotos, setFilteredSecondaryPhotos] = useState<Photo[]>([])
    const [openModal, setOpenModal] = useState(false)

    const [shortLink, setShortLink] = useState<string | undefined>()

    const [isAddPhotoModalOpened, { open: openAddPhotoModal, close: closeAddPhotoModal }] = useDisclosure(false);

    const form = useForm({
        initialValues: {
            body: '',
            destinataire: '',
        },
        validate: {
            body: (value) => (value.length <= 0 ? 'Veuillez renseigner un corps de mail' : null),
            destinataire: (value) => (/^\S+@\S+$/.test(value) ? null : 'Veuillez renseigner un destinaire'),
        }
    });

    const { data: originalCar, isLoading, isFetched, } = useQuery<ICar>(['car_by_id', searchParams], async () => {
        const data = (await http.get('/car_by_id', { params: { 'carId': searchParams.get('id') } })).data as ICar
        filterPrimaryPhoto(selectedCode, data?.primary_photos)
        filterSecondaryPhoto(selectedCode, data?.secondary_photos)
        return data
    }, {
        retry: false,
        refetchOnWindowFocus: false,
    })
    const { isProd } = useIsEnv()

    const anyCheckedPhoto = filteredPrimaryPhotos.filter(photo => photo.checked).length > 0 || filteredSecondaryPhotos.filter(photo => photo.checked).length > 0
    const filteredCheckedPhoto = [...filteredPrimaryPhotos.filter(photo => photo.checked), ...filteredSecondaryPhotos.filter(photo => photo.checked)]

    const filterPrimaryPhoto = useCallback((selectedCode?: string, primary_photos?: ICar['primary_photos']) => {
        let tmp = primary_photos?.map(photo => ({ ...photo, checked: false })) ?? []
        if (selectedCode && selectedCode !== 'none')
            tmp = tmp.filter((photo) => photo.code === selectedCode)
        setFilteredPrimaryPhotos(tmp)
    }, [])

    const filterSecondaryPhoto = useCallback((selectedCode?: string, secondary_photos?: ICar['secondary_photos']) => {
        let tmp = secondary_photos?.map(photo => ({ ...photo, checked: false })) ?? []
        if (selectedCode && selectedCode !== 'none')
            tmp = tmp.filter((photo) => photo.code === selectedCode)
        setFilteredSecondaryPhotos(tmp)
    }, [])

    const providersCodes = useMemo(() => {
        return carProviders?.reduce((accumulator: { value: string, label: string }[], currentValue) => {
            if (currentValue._id === (originalCar?.provider as IProvider)?._id) {
                return accumulator.concat(currentValue.codes.map((code: string) => ({ value: code, label: code })))
            }
            return accumulator
        }, [{ label: "aucun code", value: "none" }]) ?? [{ label: "aucun code", value: "none" }]
    }, [carProviders, originalCar])

    const onCodeChange = (val: string) => {
        setSelectedCode(val)
        filterPrimaryPhoto(val, originalCar?.primary_photos)
        filterSecondaryPhoto(val, originalCar?.secondary_photos)
    }

    const onSendEmail = async () => {

        try {
            setIsEmailOpening(true)
            const { data: newShortLink } = await http.post('/attachment', { carId: originalCar?._id, photos: filteredCheckedPhoto.map(el => ({ name: el.name, path: el.path })) })
            // const newShortLink = (await http.post('/ttachment', { s3_path: key, carId: originalCar?._id, photos: promises.map(p => p.value.name) })).data
            setShortLink(newShortLink as unknown as string)
            form.values.body = `Madame, Monsieur

            Veuillez trouver le lien de téléchargement pour la demande d'information du véhicule ${originalCar?.marque || ''} ${originalCar?.famille || ''} ${originalCar?.version || ''}

            ${isProd ? 'https://www.nextfleet.fr/link/' : 'http://localhost:8080/link/'}${newShortLink}

            Cordialement
            `
            setOpenModal(true)
        } finally {
            setIsEmailOpening(false)
        }
    }

    const sendEmail = async ({ body, destinataire }: { body: string, destinataire: string }) => {
        setIsEmailSending(true)
        try {
            await http.post('/send_email', { to: destinataire, body, short_link: shortLink })
            setOpenModal(false)

        } finally {
            setIsEmailSending(false)
        }
    }

    return (
        <>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <div className="tw-mt-2 tw-flex tw-flex-nowrap tw-space-x-4">
                <div className="tw-flex-1">
                    <Input
                        placeholder={typeof originalCar?.provider !== 'string' ? originalCar?.provider.user : ''}
                        id="providerCodeSelector"
                        disabled
                    />
                </div>
                <div className="tw-flex-1">
                    <Select
                        defaultValue="none"
                        placeholder="Code"
                        data={providersCodes}
                        id="providerCodeSelector"
                        onChange={onCodeChange}
                        disabled={!isFetched || !originalCar}
                    />
                </div>
            </div>
            <div className="tw-m-2 tw-flex tw-flex-nowrap tw-space-x-4">
                <h3>{typeof originalCar?.provider !== 'string' && originalCar?.provider.user}</h3>
            </div>

            <div className="tw-flex tw-sticky tw-top-0" style={{ zIndex: 20 }} >
                <Button leftIcon={<IconSend />} disabled={!anyCheckedPhoto} onClick={onSendEmail} loading={isEmailOpening} fullWidth>
                    Envoyer un email
                </Button>
            </div >

            < div className="tw-mt-2" >
                <h1 className="tw-text-3xl tw-font-bold">
                    Informations
                </h1>
                <div>{originalCar?.immatriculation}</div>
                <div>{originalCar?.km && `${originalCar?.km}km`}</div>
                <div>{originalCar?.price && `${originalCar?.price}€`}</div>
                <div>{originalCar?.marque && `${originalCar?.marque}`}</div>
                <div>{originalCar?.famille && `${originalCar?.famille}`}</div>
                <div>{originalCar?.version && `${originalCar?.version}`}</div>
            </div >
            <div className="tw-mt-2">
                <h1 className="tw-text-3xl tw-font-bold">
                    Photos
                </h1>
                <div className="tw-my-6">
                    <Button onClick={openAddPhotoModal}>Ajouter une nouvelle photo</Button>
                </div>
                <h1 className="tw-text-lg tw-font-bold">
                    photo véhicule
                </h1>
                <div className="tw-flex tw-flex-wrap">
                    <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-8">
                        {filteredPrimaryPhotos?.map((photo, index) =>
                            <div className="tw-w-2/5 tw-relative" key={photo.path + '1'}>
                                <div className="tw-absolute tw-z-10 tw-right-0">
                                    <Checkbox
                                        onChange={() => {
                                            let arr = [...filteredPrimaryPhotos]
                                            arr.splice(index, 1, { ...filteredPrimaryPhotos[index], checked: !filteredPrimaryPhotos[index].checked })
                                            setFilteredPrimaryPhotos(arr)
                                        }}
                                        checked={photo.checked}
                                    />
                                </div>
                                <Image
                                    fit="contain"
                                    radius="md"
                                    src={photo.path}
                                    alt="Random unsplash image"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <h1 className="tw-text-lg tw-font-bold">
                    photo privés
                </h1>
                <div className="tw-flex tw-flex-wrap">
                    <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-8">
                        {filteredSecondaryPhotos?.map((photo, index) =>
                            <div className="tw-w-2/5 tw-relative" key={`${photo.path}_${index}`}>
                                <div className="tw-absolute tw-z-10 tw-right-0">
                                    <Checkbox
                                        onChange={() => {
                                            let arr = [...filteredSecondaryPhotos]
                                            arr.splice(index, 1, { ...filteredSecondaryPhotos[index], checked: !filteredSecondaryPhotos[index].checked })
                                            setFilteredSecondaryPhotos(arr)
                                        }}
                                    />
                                </div>
                                <Image
                                    fit="contain"
                                    radius="md"
                                    src={photo.path}
                                    alt="Random unsplash image"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div >

            <AddPhotoModal
                opened={isAddPhotoModalOpened}
                onClose={closeAddPhotoModal}
                providersCodes={providersCodes}
            />
            <Modal
                opened={openModal}
                onClose={() => { setOpenModal(false) }}
                title="Formulaire d'email client"
                fullScreen
                transitionProps={{ transition: 'fade', duration: 200 }}
            >
                <form onSubmit={form.onSubmit(sendEmail)}>
                    <TextInput
                        id="body"
                        label="Destinataire"
                        placeholder="email@exemple.fr"
                        {...form.getInputProps('destinataire')}
                    />
                    <Textarea
                        id="body"
                        radius="md"
                        label="Contenu"
                        placeholder="Madame, Monsieur ..."
                        withAsterisk
                        autosize
                        {...form.getInputProps('body')}
                    />
                    <CopyButton value={`${isProd ? 'https://www.nextfleet.fr/link/' : 'http://localhost:8080/link/'}${shortLink}`} timeout={2000}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? 'Copier' : 'Copier le lien'} withArrow position="right">
                                <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                                    {copied ? (
                                        <IconCheck style={{ width: rem(16) }} />
                                    ) : (
                                        <IconCopy style={{ width: rem(16) }} />
                                    )}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                    <Group position="right" mt="md">
                        <Button type="submit" fullWidth disabled={!form.isValid()} loading={isEmailSending}>Envoyer</Button>
                    </Group>
                </form>
            </Modal >
        </>
    )
}

export default DetailCar