import React from 'react';
import '../App.css';
import { useIsLoggin } from 'hooks/useUserAccount';
import Search from './Search';
import Login from './Login';


function Home() {
    const isLogging = useIsLoggin()

    if (isLogging)
        return <Search />

    return <Login />
}

export default Home