import { Loader } from "@mantine/core"
import { useQuery } from "@tanstack/react-query"
import ItemResult from "./ItemResult"
import http from '@plugin/http'
import { useSearchParams } from "react-router-dom"
import ICar from "types/Car"

const ListResult = () => {
  const [searchParams] = useSearchParams();

  const { data: searchResults, isLoading } = useQuery<ICar[]>(['search', searchParams], async () => {
    return (await http.get('/search', { params: { 'immatriculation': searchParams.get('immatriculation'), marque: searchParams.get('marque') } })).data
  }, {
    retry: false,
    refetchOnWindowFocus: false,
  })

  return (
    <div>
      {isLoading &&
        <div className="tw-flex tw-flex-row tw-justify-center tw-pt-4">
          <Loader variant="bars" />
        </div>
      }
      {
        searchResults?.length === 0 &&
        <div className="tw-flex tw-flex-col">
          <div className=" tw-mt-4">Aucun résultat pour l'immatriculation "{searchParams.get('immatriculation')}"
          </div>

        </div>
      }
      {searchResults?.map(el => <ItemResult key={el._id} immatriculation={el.immatriculation} title={`${el.marque ?? ''} ${el.famille ?? ''} ${el.version ?? ''}`} createdBy={el?.createdBy?.username} km={el.km} picture={el.primary_photos?.[0]?.path}
        carId={el._id} />)
      }

      {/* <div className="tw-flex tw-flex-row tw-justify-center">
        <Pagination className="tw-mt-6" total={10} radius="md" />
      </div> */}
    </div>
  )
}

export default ListResult