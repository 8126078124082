import { useMemo, useState } from 'react';
import AdvancedEdition from '@components/AdvancedEdition';
import { Box, Button, Group, Image, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMutation } from '@tanstack/react-query'
import http from '@plugin/http'
import DropZone from '@components/DropZone';
import { useGetUserAccount } from 'hooks/useUserAccount';
import useS3 from 'hooks/useS3';
import usePhoto from 'hooks/usePhoto';
import { useNavigate } from 'react-router-dom';
import { MARQUES } from 'types/Car';
declare global {
  interface Window {
    ReactNativeWebView: any;
    new_pictures: any
  }
}



const AddCar = () => {
  const { data: user } = useGetUserAccount({})
  const [selected_pictures, setSelectedPictures] = useState<{ name: string, base64: string }[]>([])
  const { addPrimaryPhoto } = usePhoto()
  const { uploadToS3 } = useS3()
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      immatriculation: '',
      marque: '',
      modele: '',
      annee: '',
      kms: '',
      type: '',
      pays: '',
      version: '',
      couleur: '',
      providerId: '',
      providerCode: ''
    },
    validate: {
      immatriculation: (value) => (value.length < 2 ? 'Immatriculation incorrecte' : null),
      providerId: (value) => (!value ? 'Fichier de données incorrect' : null),
      providerCode: (value) => (selected_pictures.length > 0 && !value ? 'Code du fichier de données incorrect' : null)
    }
  });

  const providerIds = useMemo(() =>
    user?.provider?.map((el: any) => ({ value: el._id, label: `${el.user} - (${el.codes.join(',')})` })) ?? []
    , [user])

  const providersCodes = useMemo(() => {
    return user?.provider?.reduce((accumulator: { value: string, label: string }[], currentValue) => {
      if (currentValue._id === form?.values?.providerId)
        return accumulator.concat(currentValue.codes.map((code: string) => ({ value: code, label: code })))
      return accumulator
    }, []) ?? []
  }, [user, form?.values?.providerId])

  const onPictures = (pictures: string) => {
    setSelectedPictures(JSON.parse(pictures).map((el: any) => ({ name: el.filename, base64: el.base64 })))
  }

  const { mutate, isLoading } = useMutation((form) => {
    return http.post('/car', form)
  }, {
    onSuccess: async data => {
      try {
        for (let i = 0; i < selected_pictures.length; i++) {
          const { key } = await uploadToS3(selected_pictures[i].base64)
          await addPrimaryPhoto(data.data, [{ name: selected_pictures[i].name, code: form.values.providerCode, path: key }])
        }
      } catch (e) {
        console.log(e)
      } finally {
        navigate({ pathname: '/detail_car', search: `id=${data.data}` })
        return data
      }
    },
    onError: ((err) => {
      console.log(err);
    })
  })

  const onSubmit = (values_form: any) => {
    if (!form.isValid())
      return
    mutate(values_form);
  }

  return (
    <>
      <Box>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            label="Immatriculation"
            placeholder="AB-768-KD"
            id="immatriculation"
            {...form.getInputProps('immatriculation')}
            required
          />
          <Select
            label="Fichier de données"
            placeholder="Fichier de données"
            data={providerIds}
            id="providerIds"
            {...form.getInputProps('providerId')}
            required
          />

          {form.values.providerId &&
            <Select
              label="Code"
              placeholder="Code"
              data={providersCodes}
              id="providerCodeSelector"
              {...form.getInputProps('providerCode')}
              required
            />}
          <div className="tw-my-3">
            <DropZone onChange={onPictures} />
          </div>

          <div className='tw-my-3 tw-flex tw-flex-wrap tw-gap-8'>
            {
              selected_pictures?.map((picture: any, index: number) =>
                <div key={index}>
                  <Image
                    radius="md"
                    withPlaceholder
                    fit="contain"
                    height={80}
                    src={picture.base64}
                    alt="Random unsplash image"
                  />
                </div>
              )
            }

          </div>

          <AdvancedEdition text="Informations supplémentaires">
            <Select
              label="Marque"
              placeholder="Mercedes"
              data={MARQUES}
              searchable
              {...form.getInputProps('marque')}
            />
            <TextInput
              label="modele"
              placeholder="modele"
              {...form.getInputProps('modele')}
            />
            <TextInput
              label="Année"
              placeholder="Année"
              {...form.getInputProps('annee')}
            />
            <TextInput
              label="Kilomètres"
              placeholder="Kilomètres"
              {...form.getInputProps('kms')}
            />
            <TextInput
              label="type"
              placeholder="VO/VD/VN"
              {...form.getInputProps('type')}
            />
            <TextInput
              label="Pays"
              placeholder="France"
              {...form.getInputProps('pays')}
            />
            <TextInput
              label="Version"
              placeholder="Version 350 AVANTGARDE 7GTRO"
              {...form.getInputProps('version')}
            />
            <TextInput
              label="couleur"
              placeholder="Noir"
              {...form.getInputProps('couleur')}
            />
          </AdvancedEdition>
          <Group position="right" mt="md">
            <Button type="submit" fullWidth loading={isLoading}>Ajouter</Button>
          </Group>
        </form>
      </Box>
    </>
  )
}

export default AddCar