import { Button, Group, Image, Modal, Select } from "@mantine/core"
import { useForm } from "@mantine/form";
import DropZone from "./DropZone";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import useUploadPhotos from "query/useUploadPhotos";
import INativeFile from "types/NativeFile";


const AddPhotoModal = ({ opened, onClose, providersCodes }: { opened: boolean, onClose: () => void, providersCodes: { label: string, value: string }[] }) => {

    const [selected_pictures, setSelectedPictures] = useState<{ name: string, base64: string }[]>([])

    const form = useForm<{
        providerCode: string,
        photos: INativeFile[],
        type_photo: 'primary' | 'secondary'
    }>({
        initialValues: {
            providerCode: 'none',
            photos: [],
            type_photo: 'primary'
        },
        validate: {
            providerCode: (value) => (value === 'none' ? 'Veuillez renseigner un code de console' : null),
            type_photo: (value) => (!value),
            photos: (value) => (value.length <= 0)
        }
    });

    const [searchParams] = useSearchParams()
    const onCloseModal = () => {
        onClose()
        setSelectedPictures([])
        form.reset()
    }
    const { mutateAsync: mutateAsyncPhotos, isLoading: isLoadingPrimaryPhotos } = useUploadPhotos({ onSuccess: onCloseModal })

    const savePhoto = async ({ providerCode, photos, type_photo }: {
        providerCode: string;
        photos: INativeFile[];
        type_photo: 'primary' | 'secondary'
    }) => {
        mutateAsyncPhotos({ type: type_photo, carId: searchParams.get('id') as string, code: providerCode, pictures: photos })
    }

    const onPicturesSelected = (pictures: string) => {
        const photos = JSON.parse(pictures)
        setSelectedPictures(photos)
        form.setValues((prev) => ({ ...prev, photos }));
    }



    return (
        <Modal
            opened={opened}
            onClose={onCloseModal}
            title="Ajouter une photo"
            fullScreen
            transitionProps={{ transition: 'fade', duration: 200 }}
        >
            <form onSubmit={form.onSubmit(savePhoto)}>
                <div className="tw-mt-2 tw-flex tw-flex-col">
                    <div className="tw-flex-1">
                        <Select
                            label="Code console"
                            defaultValue="none"
                            placeholder="Code"
                            data={providersCodes}
                            id="providerCodeSelector"
                            {...form.getInputProps('providerCode')}
                        />
                    </div>
                    <div className="tw-flex-1">
                        <Select
                            label="type"
                            defaultValue="primary"
                            placeholder="type de photo"
                            data={[{ label: "véhicule", value: 'primary' }, { label: 'privée', value: 'secondary' }]}
                            id="photoType"
                            {...form.getInputProps('type_photo')}
                        />
                    </div>
                    <div className="tw-my-3">
                        <DropZone onChange={onPicturesSelected} />
                    </div>
                    <div className='tw-my-3 tw-flex tw-flex-wrap tw-gap-8'>
                        {
                            selected_pictures?.map((picture: any, index: number) =>
                                <div key={index}>
                                    <Image
                                        radius="md"
                                        withPlaceholder
                                        fit="contain"
                                        height={80}
                                        src={picture.base64}
                                        alt="Random unsplash image"
                                    />
                                </div>
                            )
                        }

                    </div>
                </div>

                <Group mt="md">
                    <Button onClick={onCloseModal} disabled={isLoadingPrimaryPhotos}>Fermer</Button>
                    <Button type="submit" disabled={!form.isValid()} loading={isLoadingPrimaryPhotos}>Sauvegarder</Button>
                </Group>
            </form>
        </Modal>
    )
}

export default AddPhotoModal